import React from 'react';
import './Footer.css';
import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {

  const socialLinks = [
    { icon: <FaLinkedin />, url: 'https://www.linkedin.com/company/quantum-stack' },
    { icon: <FaTwitter />, url: 'https://x.com/quantum_stack' },
    { icon: <FaFacebook />, url: 'https://www.facebook.com/quantumstack' },
    { icon: <FaInstagram />, url: 'https://www.instagram.com/quantumstack/' },
    { icon: <FaWhatsapp />, url: 'https://wa.me/7558793773?text=Hello, I want to know moure about your services' },
  ];

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section company-info">
          {/* <h3>Quantum-Stack</h3>
          <p className="company-description">
            Transforming businesses through innovative technology solutions and digital excellence.
          </p> */}
          <h3>Follow Us </h3> 
          <div className="social-links">
            {socialLinks.map((social, index) => (
              <a key={index} href={social.url} className="social-link" target="_blank" rel="noopener noreferrer">
                {social.icon}
              </a>
            ))}
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <p className="copyright">
            &copy; {new Date().getFullYear()} Quantum-Stack. All Rights Reserved.
          </p>
        </div>
      </div>

      <div className="footer-decorator">
        <div className="decorator-line"></div>
        <div className="decorator-circles">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="circle"></div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

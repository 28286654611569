import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <section id="services" className="services">
      <h1> Services </h1>
      <ul>
          <li>Website Development</li>
          <li>Mobile App Development</li>
          <li>ERP Solutions</li>
          <li>E-Commerce Solutions</li>
          <li>Custom Software Development</li>
          <li>API Development & Integration</li>
          <li>Support & Maintenance Services</li>
          <li>Technology Consulting</li>
          <li>Training & Internship Programs</li> 
          <li>UI/UX Design Services</li>
          <li>Digital Marketing and SEO Services</li>
         

      </ul>
    </section>
  );
};

export default Services;

import React from 'react';
import './Portfolio.css';

const Portfolio = () => {
  const portfolioItems = [
    {
      "title": "Vehicle QR Code Management System",
      "description": "A system to generate QR codes for vehicles and notify owners if parked incorrectly."
    },
    {
      "title": "Restaurant ERP",
      "description": "A centralized order management system for restaurants."
    },
    {
      "title": "Online Examination System",
      "description": "A platform for online exams with real-time grading and analytics."
    },
    {
      "title": "Task Manager",
      "description": "An intuitive app to help users organize and manage their tasks."
    }
  ];

  return (
    <section id="portfolio" className="portfolio">
      <h2>Portfolio</h2>
      <div className="portfolio-grid">
        {portfolioItems.map((item, index) => (
          <div 
            key={index} 
            className="portfolio-item"
            role="button"
            tabIndex={0}
          >
            <div className="content">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
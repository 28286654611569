import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const slides = [
    {
      title: "Welcome to Quantum Stack",
      description: "We build innovative web and mobile solutions tailored for your business.",
    },
    {
      title: "Expert Solutions",
      description: "Delivering cutting-edge technology solutions with proven expertise.",
    },
    {
      title: "Client Success",
      description: "Transforming businesses through digital excellence and innovation.",
    },
    {
      title: "Innovate with Us",
      description: "Empowering businesses with next-generation technology solutions.",
    },
    {
      title: "Future-Ready Innovations",
      description: "Equipping businesses with tools to thrive in an ever-evolving tech landscape.",
    },
    {
      title: "End-to-End Solutions",
      description: "From concept to execution, we ensure a seamless digital experience.",
    }
  ];

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setIsVisible(true);
    }, 1000);

    // Slide rotation logic
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section id="home" className={`home ${isLoading ? 'loading' : ''}`}>
      <div className="background-shapes">
        {[...Array(5)].map((_, i) => (
          <div key={i} className={`shape shape-${i + 1}`}></div>
        ))}
      </div>

      <div className="content-wrapper">
        {isLoading ? (
          <div className="loader">
            <div className="loader-circle"></div>
            <div className="loader-text">Loading</div>
          </div>
        ) : (
          <>
            <div className={`slide-container ${isVisible ? 'visible' : ''}`}>
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`slide ${index === currentSlide ? 'active' : ''}`}
                >
                  <h2 className="title">
                    {slide.title}
                    {/* {slide.title.split('').map((char, i) => (
                      <span
                        key={i}
                        className="animate-char"
                        style={{
                          animationDelay: `${i * 0.1}s`,
                          width: char === ' ' ? '0.3em' : 'auto',
                        }}
                      >
                        {char === ' ' ? '\u00A0' : char}
                      </span>
                    ))} */}
                  </h2>
                  <p className="description">{slide.description}</p>
                  <div className="cta-container">
                  <a href='#contact'>
                     <button className="cta-button secondary">Contact Us</button>
                  </a>

                  </div>
                </div>
              ))}
            </div>
            <div className="navigation">
              <div className="dots">
                {slides.map((_, index) => (
                  <span
                    key={index}
                    className={`dot ${index === currentSlide ? 'active' : ''}`}
                    onClick={() => setCurrentSlide(index)}
                  ></span>
                ))}
              </div>
              {/* <div className="slide-progress">
                <div
                  className="progress-bar"
                  style={{ width: `${(currentSlide ) * (100 / slides.length)}%` }}
                ></div>
              </div> */}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Home;

import React, { useState, useEffect } from 'react';
import './About.css';

const About = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('mission');
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.1 }
    );

    const section = document.querySelector('.about');
    if (section) observer.observe(section);

    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      setMousePosition({ x: clientX, y: clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      if (section) observer.unobserve(section);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);


  return (
    <section 
      id="about" 
      className={`about ${isVisible ? 'visible' : ''}`}
      style={{
        '--mouse-x': `${mousePosition.x}px`,
        '--mouse-y': `${mousePosition.y}px`
      }}
    >
      <div className="magical-background">
        <div className="orb"></div>
        <div className="orb"></div>
        <div className="orb"></div>
      </div>

      <div className="about-container">
        <div className="about-header">
          <div className="magical-title">
            <h2 className="section-title">About Us</h2>
            <div className="title-particles">
              {[...Array(20)].map((_, i) => (
                <span key={i} className="particle"></span>
              ))}
            </div>
          </div>
          <p className="section-subtitle">Where Innovation Meets Magic</p>
        </div>

        <div className="about-grid">
  <div className="about-content magical-card">
    {/* Tab Navigation */}
    <div className="tab-navigation">
      {['mission', 'vision', 'values'].map((tab) => (
        <button 
          key={tab}
          className={`tab-btn ${activeTab === tab ? 'active' : ''}`}
          onClick={() => setActiveTab(tab)}
        >
          <span className="btn-background"></span>
          <span className="btn-content">{tab.charAt(0).toUpperCase() + tab.slice(1)}</span>
        </button>
      ))}
    </div>

    {/* Tab Content */}
    <div className="tab-content">
      {/* Mission Content */}
      {activeTab === 'mission' && (
        <div className="tab-pane magical-text">
          <h3>Our Mission</h3>
          <p>Transforming digital dreams into reality through innovative solutions.</p>
          <ul className="magical-list">
            <li>Cutting-edge Development</li>
            <li>Digital Excellence</li>
            <li>Creative Solutions</li>
            <li>Future Innovation</li>
          </ul>
        </div>
      )}

      {/* Vision Content */}
      {activeTab === 'vision' && (
        <div className="tab-pane magical-text">
          <h3>Our Vision</h3>
          <p>Empowering Businesses with Innovative and Future-Ready IT Solutions.</p>
          <ul className="magical-list">
            <li>Client-Centric Innovation</li>
            <li>Future-Ready Technology</li>
            <li>Global Impact</li>
            <li>Sustainability and Growth</li>
            <li>Employee Empowerment</li>
            <li>Trusted Partnerships</li>
          </ul>
        </div>
      )}

      {/* Values Content */}
      {activeTab === 'values' && (
        <div className="tab-pane magical-text">
          <h3>Our Values</h3>
          <p>Guiding principles that shape our culture and drive our success.</p>
          <ul className="magical-list">
            <li>Integrity and Transparency</li>
            <li>Commitment to Excellence</li>
            <li>Innovation and Creativity</li>
            <li>Customer-First Approach</li>
          </ul>
        </div>
      )}
    </div>
  </div>
</div>

      </div>
    </section>
  );
};

export default About;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Career.css';

const careerData = [
    {
      "id": 1,
      "title": "Frontend Developer",
      "location": "Pune, India",
      "description": "Work with React.js to build dynamic and user-friendly web applications.",
      "requirements": [
        "Experience with React.js",
        "Strong understanding of HTML/CSS",
        "Proficiency in JavaScript",
        "Responsive design experience"
      ]
    },
    {
      "id": 2,
      "title": "Java (SpringBoot) Developer",
      "location": "Pune, India",
      "description": "Develop and maintain Java-based applications using SpringBoot framework.",
      "requirements": [
        "Experience with Java and SpringBoot",
        "Knowledge of RESTful web services",
        "Familiarity with SQL and NoSQL databases"
      ]
    },
    {
      "id": 3,
      "title": "UI/UX Designer",
      "location": "Pune, India",
      "description": "Design user-friendly and visually appealing interfaces that provide excellent user experiences.",
      "requirements": [
        "Proficiency in design tools (e.g., Adobe XD, Sketch)",
        "Strong understanding of user-centered design principles",
        "Excellent visual design skills"
      ]
    },
    {
      "id": 4,
      "title": "QA Engineer",
      "location": "Pune, India",
      "description": "Ensure the quality of software applications through rigorous testing and bug reporting.",
      "requirements": [
        "Experience in manual and automated testing",
        "Knowledge of testing tools (e.g., Selenium, JUnit)",
        "Strong analytical and problem-solving skills"
      ]
    },
    {
      "id": 5,
      "title": "Sales Executive",
      "location": "Pune, India",
      "description": "Drive business growth by identifying and engaging with new clients, promoting Quantum Stack services.",
      "requirements": [
        "Excellent communication and negotiation skills",
        "Proven track record in sales",
        "Ability to build and maintain client relationships"
      ]
    }  
];

const Career = () => {
  const [appliedJobs, setAppliedJobs] = useState([]);

  const handleApply = (jobId) => {
    if (!appliedJobs.includes(jobId)) {
      setAppliedJobs([...appliedJobs, jobId]);
    }
  };

  return (
    <div className="career-container" id='career'>
      <h1>Career Opportunities</h1>
      <div className="career-list">
        {careerData.map((job) => (
          <div key={job.id} className="career-card">
            <h2>{job.title}</h2>
            <p><strong>Location:</strong> {job.location}</p>
            <p>{job.description}</p>
            <h3>Requirements:</h3>
            <ul>
              {job.requirements.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
            <a className='applylink' href='https://forms.gle/bNZKVLPioMoEzh396' target="_blank" rel="noopener noreferrer">
            <button className="cta-button primary">
             Apply
            </button></a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Career;

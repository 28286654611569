import React, { useState } from 'react';
import './Contact.css';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    subject: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://quantum-stack.com/api/contact', formData);
      if (response.status === 200) {
        console.log("Data sent successfully");
        setIsSubmitted(true);
        setTimeout(() => setIsSubmitted(false), 3000);
      } else {
        throw new Error('Failed to send data');
      }
    } catch (error) {
      console.error("Error in sending data", error);
      setIsFailed(true);
      setTimeout(() => setIsFailed(false), 5000);
    }
   
  };

  return (
    <section id="contact" className="contact">
      <div className="contact-container">
        <div className="contact-info">
          <h2 className="section-title">Get in Touch</h2>
          <p className="section-description">
            Ready to transform your digital presence? Let's discuss how we can help your business grow.
          </p>

          <div className="info-cards">
            <div className="info-card">
              <div className="icon-wrapper">
                <i className="icon location-icon">📍</i>
              </div>
              <h3>Visit Us</h3>
              <p>1st floor, Dange Citadel,  <br/>
              Dange Chowk, Yashoda Colony, Thergaon, 
             Pune, Maharashtra <br/> - 411033</p>
            </div>

            <div className="info-card">
              <div className="icon-wrapper">
                <i className="icon email-icon">✉️</i>
              </div>
              <h3>Email Us</h3>
              <p>
               <a href="mailto:info@quantumstack.com">info@quantumstack.com</a>
              </p>
            </div>

            <div className="info-card">
              <div className="icon-wrapper">
                <i className="icon phone-icon">📞</i>
              </div>
              <h3>Call Us</h3>
             
            <p>
         <a href="tel:+917558793773">+91 75587 93773</a>
            </p>
             
            </div>
          </div>
        </div>

        <div className="contact-form-container">
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-header">
              <h3>Send us a Message</h3>
              <p>Fill out the form below and we'll get back to you shortly.</p>
            </div>

            <div className="form-group">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Your Name"
                className="form-input"
              />
              <div className="input-highlight"></div>
            </div>

            <div className="form-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Your Email"
                className="form-input"
              />
              <div className="input-highlight"></div>
            </div>

            <div className="form-group">
              <input
                type="text"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                required
                placeholder="Mobile Number"
                className="form-input"
              />
              <div className="input-highlight"></div>
            </div>

            <div className="form-group">
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                placeholder="Subject"
                className="form-input"
              />
              <div className="input-highlight"></div>
            </div>

            <div className="form-group">
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder="Your Message"
                className="form-input"
                rows="5"
              ></textarea>
              <div className="input-highlight"></div>
            </div>

            <button type="submit" className="submit-button">
              Send Message
              <span className="button-highlight"></span>
            </button>

            {isSubmitted && (
              <div className="success-message">
                Thank you! Your message has been sent successfully.
              </div>
            )}
            {isFailed && (
              <div className="failed-message">
               Sorry, your message could not be sent. Please try again later.
              </div>
            )}
          </form>
        </div>

      {/* Map Section */}
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d945.2700097182902!2d73.76442401549994!3d18.615469400658746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9ccdd5c26cf%3A0x1c3ab191b5427b97!2sSWAMI%20VIVEKANANDA%20STUDYROOM%20DANGE%20CHOWK!5e0!3m2!1sen!2sin!4v1736417797674!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Our Location"
        ></iframe>
      </div>
      </div>
    </section>
  );
};

export default Contact;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import Services from './Services';
import Portfolio from './Portfolio';
import About from './About';
import Contact from './Contact';
import Career from './Career';
import Footer from './Footer';

function App() {
  return (
    <Router>
      <Header />
      <Home/>
      <Services/>
      <Career/>
      <Portfolio/>
      <About/>
      <Contact/>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/services" element={<Services />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/career" element={<Career />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
